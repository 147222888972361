@font-face {
  font-family: 'Calton Sans';
  src: url('../../assets/fonts/calton-elegance-script.otf');
  src: local('☺'), url('../../assets/fonts/calton-elegance-script.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.ant-btn {
  background-color: #cc9c44;
  border-color: #cc9c44;
  border-radius: 10px;
}
.ant-btn:hover {
  background-color: #d0bc8a;
  border-color: #d0bc8a;
}
footer #copyright img {
  background-color: #333;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
@media (max-width: 991px) {
  footer .footer-link {
    padding-left: 0 !important;
  }
}
h2 {
  font-size: 2rem;
  color: #cc9c44;
}
#header-navbar #header-main-hamburger span {
  background: #cc9c44 !important;
}
#header-navbar #header-main-logo img {
  top: -25px;
  position: absolute;
  transition: 0.3s all ease-in-out;
  height: 120px;
  max-height: 999px;
  max-width: 999px;
  width: auto;
}
#header-navbar.header-scrolled #header-main-logo img {
  transition: 0.3s all ease-in-out;
  height: 40px;
  width: auto;
  top: -25px;
  max-height: 70px;
  max-width: 999px;
}
.page-home #section-promo {
  padding-top: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 0;
  height: 70vh;
}
@media (max-width: 768px) {
  .page-home #section-promo {
    height: unset;
  }
}
.page-home #section-promo .ant-col {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  height: 100%;
}
.page-home #section-promo .ant-col p {
  font-family: "Calton Sans", sans-serif;
  font-size: 3rem;
}
.page-home #section-promo .ant-col img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  object-position: center -100px;
}
@media (max-width: 1600px) {
  .page-home #section-promo .ant-col img {
    object-position: unset;
  }
}
@media (max-width: 991px) {
  .page-home #section-promo .ant-col img {
    position: relative;
    margin-bottom: 0;
  }
}
.page-home #section-promo .ant-col:nth-child(2) {
  padding: 50px;
  padding-top: 150px;
  height: 100%;
  background-color: #e7dfd0;
  background-image: url('../../assets/line-work.png');
  background-position: bottom -550px center;
  background-repeat: no-repeat;
}
@media (max-width: 1600px) {
  .page-home #section-promo .ant-col:nth-child(2) {
    background-position: bottom -400px center;
  }
}
@media (max-width: 1199px) {
  .page-home #section-promo .ant-col:nth-child(2) {
    background-position: bottom -300px center;
  }
}
@media (max-width: 991px) {
  .page-home #section-promo .ant-col:nth-child(2) {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
.page-home #section-promo .ant-col:nth-child(2) blockquote {
  padding-left: 0;
  border: none;
  font-size: 2rem;
  width: 70%;
}
@media (max-width: 1199px) {
  .page-home #section-promo .ant-col:nth-child(2) blockquote {
    width: 100%;
  }
}
.page-home #section-intro {
  position: relative;
  overflow: visible;
  margin-bottom: -250px;
}
@media (max-width: 1600px) {
  .page-home #section-intro {
    margin-bottom: -180px;
  }
}
@media (max-width: 1199px) {
  .page-home #section-intro {
    margin-bottom: -50px;
  }
}
@media (max-width: 768px) {
  .page-home #section-intro {
    margin-bottom: 0;
  }
}
.page-home #section-intro a {
  float: right;
}
.page-home #section-intro h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.page-home #section-intro .cms-module-column-layout-content p img {
  position: relative;
  top: -150px;
  z-index: 999;
  float: left;
}
.page-home #section-intro .cms-module-column-layout-content p img:first-child {
  z-index: 2;
}
@media (max-width: 768px) {
  .page-home #section-intro .cms-module-column-layout-content p:nth-child(1) img {
    top: 0;
    position: relative;
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .page-home #section-intro .cms-module-column-layout-content p:nth-child(2) img {
    display: none;
  }
}
.page-home #section-intro .ant-col {
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 991px) {
  .page-home #section-intro .ant-col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.page-home #section-intro .ant-col:nth-child(2) {
  padding: 20px;
}
.page-home #section-services-extra {
  min-height: 100vh;
}
.page-home #section-services-extra:before {
  content: '';
  background-image: url('../../assets/connection-2.png');
  background-repeat: no-repeat;
  position: absolute;
  background-position: top 50px left;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .page-home #section-services-extra:before {
    background-position: center right -150px;
  }
}
.page-home #section-services-extra h2 {
  font-family: "Calton Sans", sans-serif;
  font-size: 5rem;
}
.page-home #section-services-extra .ant-col {
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 991px) {
  .page-home #section-services-extra .ant-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .page-home #section-services-extra .ant-col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.page-home #section-services-extra .ant-col:nth-child(2) {
  padding: 20px;
}
@media (max-width: 991px) {
  .page-home #section-services-extra .ant-col:nth-child(2) {
    display: none;
  }
}
.page-home #section-service {
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../assets/sand-texture.jpeg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: calc(50% - 130px) 100%;
}
@media (max-width: 1600px) {
  .page-home #section-service {
    background-position: calc(50% - 30px) 100%;
  }
}
@media (max-width: 768px) {
  .page-home #section-service {
    background-position: calc(50%) 100%;
  }
}
.page-home #section-service .ant-col {
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1199px) {
  .page-home #section-service .ant-col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.page-home #section-service .ant-col:nth-child(2) {
  padding: 20px;
}
@media (max-width: 1600px) {
  .page-home #section-service .ant-col:nth-child(2) {
    display: none;
  }
}
@media (max-width: 1600px) {
  .page-home #section-service .ant-col-md-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 768px) {
  .page-home #section-service .ant-col-md-8 {
    flex: unset;
    max-width: unset;
  }
}
@media (max-width: 991px) {
}
.page-home #section-service h3 {
  font-size: 3rem;
  font-family: "Calton Sans", sans-serif;
}
.page-home #section-contact {
  background-color: #e7dfd0;
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../assets/line-work.png');
  background-repeat: no-repeat;
}
.page-home #section-contact:before {
  content: '';
  background-image: url('../../assets/connection-1-gold.png');
  background-repeat: no-repeat;
  position: absolute;
  background-position: top 50px left;
  opacity: 0.6;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (max-width: 991px) {
  .page-home #section-contact:before {
    opacity: 0.3;
  }
}
.page-home #section-contact .ant-col {
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 991px) {
  .page-home #section-contact .ant-col {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1600px) {
  .page-home #section-contact .ant-col:nth-child(1) {
    flex: 0 0 30%;
    max-width: 30%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1600px) {
  .page-home #section-contact .ant-col:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1199px) {
  .page-home #section-contact .ant-col:nth-child(2) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 991px) {
  .page-home #section-contact .ant-col:nth-child(1) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 768px) {
  .page-home #section-contact .ant-col:nth-child(1),
  .page-home #section-contact .ant-col:nth-child(2) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-home #section-contact .ant-col:nth-child(2) img {
    display: none;
  }
}
.page-home #section-contact .ant-col:nth-child(3) img {
  z-index: 3;
  position: absolute;
  top: -300px;
  left: -50px;
  width: 80%;
}
@media (max-width: 991px) {
  .page-home #section-contact .ant-col:nth-child(3) img {
    display: none;
  }
}
