@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

.page-home {

  #section-promo {
    padding-top: 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 0;
    height: 70vh;

    @media @tablet-down {
      height: unset;
    }

    .ant-col {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
      height: 100%;

      p {
        font-family: "Calton Sans", sans-serif;
        font-size: 3rem;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 0;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);

        object-position: center -100px;
        @media @extra-large-desktop-down {
          object-position: unset;
        }

        @media @desktop-down {
          position: relative;
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        padding: 50px;
        padding-top: 150px;
        height: 100%;
        background-color: rgb(231,223,208);
        background-image: url('../../assets/line-work.png');
        background-position: bottom -550px center;
        background-repeat: no-repeat;

        @media @extra-large-desktop-down {
          background-position: bottom -400px center;
        }

        @media @large-desktop-down {
          background-position: bottom -300px center;
        }

        @media @desktop-down {
          padding-top: @theme-section-padding-mobile;
          padding-bottom: 100px;
        }


        blockquote {
          padding-left: 0;
          border: none;
          font-size: 2rem;
          width: 70%;

          @media @large-desktop-down {
            width: 100%;
          }
        }
      }
    }
  }

  #section-intro {
    position: relative;
    overflow: visible;
    //padding-bottom: 0px;
    margin-bottom: -250px;
    //
    @media @extra-large-desktop-down {
      margin-bottom: -180px;
    }

    @media @large-desktop-down {
      margin-bottom: -50px;
    }

    @media @tablet-down {
      margin-bottom: 0;
    }

    a {
      float: right;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    .cms-module-column-layout-content {

      p img {
        position: relative;
        top: -150px;
        z-index: 999;
        float: left;

        &:first-child {
          z-index: 2;
        }
      }

      p:nth-child(1) {
        img {
          //position: absolute;
          @media @tablet-down {
            top: 0;
            position: relative;
            margin-bottom: 0;
          }
        }
      }

      p:nth-child(2) {
        img {
          //top: 800%;
          //position: absolute;
          //@media @extra-large-desktop-down {
          //  top: 250px;
          //}
          //@media @large-desktop-down {
          //  top: 150px;
          //}


          @media @tablet-down {
            display: none;
          }
        }
      }
    }

    .ant-col {
      padding-left: 100px;
      padding-right: 100px;

      @media @desktop-down {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-child(2) {
        padding: 20px;
      }
    }
  }

  #section-services-extra {

    &:before {
      content: '';
      background-image: url('../../assets/connection-2.png');
      background-repeat: no-repeat;
      position: absolute;
      background-position: top 50px left;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;


      @media @desktop-down {
        background-position: center right -150px;
      }

    }

    min-height: 100vh;
    h2 {
      font-family: "Calton Sans", sans-serif;
      font-size: 5rem;
    }

    .ant-col {
      padding-left: 100px;
      padding-right: 100px;


      @media @desktop-down {
        &-md-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      @media @desktop-down {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-child(2) {
        padding: 20px;
        @media @desktop-down {
          display: none;
        }
      }
    }
  }

  #section-service {

    background-image: linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.6)), url('../../assets/sand-texture.jpeg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: calc(50% - 130px) 100%;

    @media @extra-large-desktop-down {
      background-position: calc(50% - 30px) 100%;
    }

    @media @tablet-down {
      background-position: calc(50%) 100%;
    }

    .ant-col {
      padding-left: 100px;
      padding-right: 100px;

      @media @large-desktop-down {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-child(2) {
        padding: 20px;
        @media @extra-large-desktop-down {
          display: none;
        }
      }

      @media @extra-large-desktop-down {
        &-md-8 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      @media @tablet-down {
        &-md-8 {
          flex: unset;
          max-width: unset;
        }
      }

      p:nth-child(2) {
        img {
          @media @desktop-down {
            //display: none;
          }
        }
      }
    }

    h3 {
      font-size: 3rem;
      font-family: "Calton Sans", sans-serif;
    }
  }



  #section-contact {
    background-color: rgb(231,223,208);
    background-image: linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.6)), url('../../assets/line-work.png');
    background-repeat: no-repeat;

    &:before {
      content: '';
      background-image: url('../../assets/connection-1-gold.png');
      background-repeat: no-repeat;
      position: absolute;
      background-position: top 50px left;
      opacity: 0.6;
      top: -20px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      @media @desktop-down {
        opacity: 0.3;
      }
    }


    .ant-col {
      padding-left: 100px;
      padding-right: 100px;

      @media @desktop-down {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media @extra-large-desktop-down {
        &:nth-child(1) {
          flex: 0 0 30%;
          max-width: 30%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      @media @extra-large-desktop-down {
        &:nth-child(2) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      @media @large-desktop-down {
        &:nth-child(2) {
          padding-left: 50px;
          padding-right: 50px;
        }
      }

      @media @desktop-down {
        &:nth-child(1) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      @media @tablet-down {
        &:nth-child(1),&:nth-child(2) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }

        &:nth-child(2) {
         img {
           display: none;
         }
        }
      }



      &:nth-child(3) {
        img {
          z-index: 3;
          position: absolute;
          top: -300px;
          left: -50px;
          width: 80%;

          @media @desktop-down {
            display: none;
          }
        }
      }
    }
  }
}
