@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';
//
///////////////////// MAIN /////////////////
/////////////////////////////////////////////////////
@font-face {
  font-family: 'Calton Sans';
  src: url('../../assets/fonts/calton-elegance-script.otf');
  src: local('☺'), url('../../assets/fonts/calton-elegance-script.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
///////////////////// END MAIN /////////////////
/////////////////////////////////////////////////////

* {
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.ant-btn {
  background-color: @theme-color-gold;
  border-color: @theme-color-gold;
  border-radius: 10px;
  &:hover {
    background-color: @theme-color-gold-highlight;
    border-color: @theme-color-gold-highlight;
  }
}

footer #copyright img {
  background-color: #333;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

footer {
  .footer-link {
    @media @desktop-down {
      padding-left: 0 !important;
    }
  }
}

h2 {
  font-size: 2rem;
  color: @theme-color-gold;
}

