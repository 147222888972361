@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

#header-navbar {

  #header-main-hamburger span {
    background: @theme-color-gold !important;
  }

  #header-main-logo {
    img {
      top: -25px;
      position: absolute;
      transition: 0.3s all ease-in-out;
      height: 120px;
      max-height: 999px;
      max-width: 999px;
      width: auto;
    }
  }

  &.header-scrolled {

    #header-main-logo {
      img {
        transition: 0.3s all ease-in-out;
        height: 40px;
        width: auto;
        top: -25px;
        max-height: 70px;
        max-width: 999px;
      }
    }
  }
}